import { CustomFieldType } from "@clover/custom-fields/models/custom-field";
import { CustomFormFieldType } from "./enums";

export interface ObjectDefinition {
  id: string;
  name: string;
  lastModifiedAt: Date;
  fields: FieldDefinition[];
}

export interface FieldDefinition {
  isKey: boolean;
  name: string;
  isArray: boolean;
  isRequired: boolean;
  valueType: FieldValueType;
  childFields: FieldDefinition[];
}

export enum FieldValueType {
  String = 'String',
  Number = 'Number',
  Object = 'Object',
}

export interface WorkflowBusinessObject {
  // generated by workflow designer to link fields.
  id: string;
  objectDefinitionId: string;
  fields: BusinessObjectField[];
  // TODO: add idSettings: autogenerated or user input (in this case, the validation will check if the Key field is present on the form)
}

export interface BusinessObjectField {
  id: string;
  type: CustomFieldType;
  controlType: CustomFormFieldType;
  title: string;
  isKey: boolean;
  isArray: boolean;
  isRequired: boolean;
  // add is Array
  options?: { items: string[] };
}
