import { SectionHeaderComponent } from '../elements/section-header/section-header.component';
import { CustomFormElementPropertyType } from '../models/enums';
import { TextComponent } from '../elements/text/text.component';
import { type CustomFormControl } from '../models/form-element';
import { ImageComponent } from '../elements/image/image.component';
import { TextInputComponent } from '../elements/text-input/text-input.component';
import { LongTextInputComponent } from '../elements/long-text-input/long-text-input.component';
import { SingleSelectComponent } from '../elements/single-select/single-select.component';
import { MultiSelectComponent } from '../elements/multi-select/multi-select.component';
import { ContactsComponent } from '../elements/contacts/contacts.component';
import { LocationsComponent } from '../elements/locations/locations.component';
import { DateComponent } from '../elements/date/date.component';
import { NumberInputComponent } from '../elements/number-input/number-input.component';
import { ProductCategoriesComponent } from '../elements/product-categories/product-categories.component';
import { SectorsComponent } from '../elements/sectors/sectors.component';

export const ControlTypes: Record<string, CustomFormControl> = {
  Header: {
    component: SectionHeaderComponent,
    icon: 'assets/svg/custom-form-fields/text.svg',
    properties: [
      {
        key: 'headerText',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Header Text',
        placeholder: 'Type a header',
      },
      {
        key: 'subHeaderText',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Sub-Header Text',
        placeholder: 'Type a sub-header',
      },
      {
        key: 'textAlignment',
        type: CustomFormElementPropertyType.Select,
        label: 'Text Alignment',
        options: [
          {
            value: 'left',
            label: 'Left',
          },
          {
            value: 'center',
            label: 'Center',
          },
          {
            value: 'right',
            label: 'Right',
          },
        ],
      },
      {
        key: 'visible',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Field Visibility',
      },
    ],
  },
  Label: {
    component: TextComponent,
    icon: 'assets/svg/custom-form-fields/text.svg',
    properties: [
      {
        key: 'text',
        type: CustomFormElementPropertyType.TextArea,
        label: 'Text',
        placeholder: 'Type a text',
      },
      {
        key: 'visible',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Field Visibility',
      },
    ],
  },
  Image: {
    component: ImageComponent,
    properties: [
      {
        key: 'label',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Label Text',
        isNotEditable: true,
      },
      {
        key: 'visible',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Field Visibility',
      },
      {
        key: 'required',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Required',
      },
    ],
  },
  Text: {
    component: TextInputComponent,
    icon: 'assets/svg/custom-form-fields/text.svg',
    properties: [
      {
        key: 'label',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Label Text',
        isNotEditable: true,
      },
      {
        key: 'placeholder',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Placeholder',
      },
      {
        key: 'visible',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Field Visibility',
      },
      {
        key: 'required',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Required',
      },
    ],
    logicProperties: [
      {
        key: 'title',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Field Label',
        required: true,
      },
      {
        key: 'placeholder',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Placeholder',
      },
      {
        key: 'visible',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Field Visibility',
      },
      {
        key: 'required',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Required',
      },
    ],
  },
  LongText: {
    component: LongTextInputComponent,
    icon: 'assets/svg/custom-form-fields/text.svg',
    properties: [
      {
        key: 'label',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Label Text',
        isNotEditable: true,
      },
      {
        key: 'placeholder',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Placeholder',
      },
      {
        key: 'visible',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Field Visibility',
      },
      {
        key: 'required',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Required',
      },
    ],
  },
  SingleSelectList: {
    component: SingleSelectComponent,
    icon: 'assets/svg/custom-form-fields/single-select.svg',
    properties: [
      {
        key: 'label',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Label Text',
        isNotEditable: true,
      },
      {
        key: 'placeholder',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Placeholder',
      },
      {
        key: 'visible',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Field Visibility',
      },
      {
        key: 'required',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Required',
      },
    ],
    logicProperties: [
      {
        key: 'title',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Field Label',
        required: true,
      },
      {
        key: 'placeholder',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Placeholder',
      },
      {
        key: 'options',
        type: CustomFormElementPropertyType.MultilineText,
        label: 'Drop Down List Options',
        placeholder: 'Enter drop down options (one per line)',
        required: true,
      },
      {
        key: 'visible',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Field Visibility',
      },
      {
        key: 'required',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Required',
      },
    ],
  },
  MultiSelectList: {
    component: MultiSelectComponent,
    icon: 'assets/svg/custom-form-fields/single-select.svg',
    properties: [
      {
        key: 'label',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Label Text',
        isNotEditable: true,
      },
      {
        key: 'placeholder',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Placeholder',
      },
      {
        key: 'visible',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Field Visibility',
      },
      {
        key: 'required',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Required',
      },
    ],
    logicProperties: [
      {
        key: 'title',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Label Text',
        required: true,
      },
      {
        key: 'placeholder',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Placeholder',
      },
      {
        key: 'options',
        type: CustomFormElementPropertyType.MultilineText,
        label: 'Drop Down List Options',
        placeholder: 'Enter drop down options (one per line)',
        required: true,
      },
      {
        key: 'visible',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Field Visibility',
      },
      {
        key: 'required',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Required',
      },
    ],
  },
  Contact: {
    component: ContactsComponent,
    icon: 'assets/svg/custom-form-fields/contact.svg',
    properties: [
      {
        key: 'label',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Label Text',
        isNotEditable: true,
      },
      {
        key: 'placeholder',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Placeholder',
      },
      {
        key: 'visible',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Field Visibility',
      },
      {
        key: 'required',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Required',
      },
    ],
  },
  Location: {
    component: LocationsComponent,
    icon: 'assets/svg/custom-form-fields/location.svg',
    properties: [
      {
        key: 'label',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Label Text',
        isNotEditable: true,
      },
      {
        key: 'placeholder',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Placeholder',
      },
      {
        key: 'visible',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Field Visibility',
      },
      {
        key: 'required',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Required',
      },
    ],
  },
  Date: {
    component: DateComponent,
    icon: 'assets/svg/custom-form-fields/date.svg',
    properties: [
      {
        key: 'label',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Label Text',
        isNotEditable: true,
      },
      {
        key: 'placeholder',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Placeholder',
      },
      {
        key: 'visible',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Field Visibility',
      },
      {
        key: 'required',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Required',
      },
    ],
  },
  Number: {
    component: NumberInputComponent,
    icon: 'assets/svg/custom-form-fields/number.svg',
    properties: [
      {
        key: 'label',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Label Text',
        isNotEditable: true,
      },
      {
        key: 'placeholder',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Placeholder',
      },
      {
        key: 'visible',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Field Visibility',
      },
      {
        key: 'required',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Required',
      },
    ],
  },
  Sectors: {
    component: SectorsComponent,
    icon: 'assets/svg/custom-form-fields/multiselect.svg',
    properties: [
      {
        key: 'placeholder',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Placeholder',
      },
      {
        key: 'visible',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Field Visibility',
      },
      {
        key: 'required',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Required',
      },
    ],
  },
  ProductCategories: {
    component: ProductCategoriesComponent,
    icon: 'assets/svg/custom-form-fields/multiselect.svg',
    properties: [
      {
        key: 'placeholder',
        type: CustomFormElementPropertyType.TextInput,
        label: 'Placeholder',
      },
      {
        key: 'visible',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Field Visibility',
      },
      {
        key: 'required',
        type: CustomFormElementPropertyType.Switcher,
        label: 'Required',
      },
    ],
  },
};
