import { Component, Input, ViewEncapsulation } from '@angular/core';
import { type CustomFormElement } from '../models/form-element';
import { UntypedFormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFormFieldGroup } from '../models/enums';
import { FormFieldMetadata } from '../models/form-field-metadata';
import { BasicElements } from '../constants/form-elements';
import { WebsiteValidator } from '@core/validators/website.validator';
import { FormErrorMessagesComponent } from '../../core/components/form-error-messages/form-error-messages.component';
import { RenderedFormElementComponent } from '../components/rendered-form-element/rendered-form-element.component';
import { NgClass } from '@angular/common';

const METADATA_KEYS = [
  {
    key: 'publicFields',
    fieldGroup: CustomFormFieldGroup.Public,
  },
  {
    key: 'privateFields',
    fieldGroup: CustomFormFieldGroup.Private,
  },
  {
    key: 'proprietaryFields',
    fieldGroup: CustomFormFieldGroup.Proprietary,
  },
  {
    key: 'logicFields',
    fieldGroup: CustomFormFieldGroup.Logic,
  },
  {
    key: 'businessObjectFields',
    fieldGroup: CustomFormFieldGroup.BusinessObject,
  },
];

@Component({
  selector: 'form-renderer',
  templateUrl: './form-renderer.component.html',
  styleUrls: ['./form-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass, RenderedFormElementComponent, FormErrorMessagesComponent],
})
export class FormRendererComponent {
  @Input() fields: CustomFormElement[] = [];
  @Input() fieldsMetadata: FormFieldMetadata;
  @Input() data: Array<{ fieldId: string; fieldGroup: string; value: any }> = [];
  @Input() showErrors = true;

  public form: UntypedFormGroup;

  public formFields: CustomFormElement[] = [];
  public formRows: CustomFormElement[][] = [];

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({});
    this.fields.forEach((el) => {
      const controlName = `${el.id}`;

      let formElement: CustomFormElement;
      if (el.fieldGroup === CustomFormFieldGroup.Basic) {
        formElement = {
          ...BasicElements.find((basicEl) => el.fieldId === basicEl.fieldId),
        };
        try {
          formElement.data = el.data;
        } catch (error) {
          return;
        }
      } else {
        const metadataKey = METADATA_KEYS.find((key) => el.fieldGroup === key.fieldGroup).key;
        formElement = {
          ...this.fieldsMetadata[metadataKey].find((meta) => meta.id.id === el.fieldId),
        };
        formElement.data = el.data;
      }
      formElement.id = el.id;

      if (formElement.data.visible === false) {
        return;
      }

      if (el.fieldGroup === CustomFormFieldGroup.Public && el.fieldId === "1") {
        formElement.data.required = true;
      }

      if (el.rowNumber !== undefined && !(this.formRows[el.rowNumber] && this.formRows[el.rowNumber][el.rowPosition])) {
        if (!this.formRows[el.rowNumber]?.length) {
          this.formRows[el.rowNumber] = [];
        }

        this.formRows[el.rowNumber][el.rowPosition] = formElement;
      } else {
        this.formRows.push([formElement]);
      }

      const validators = [];

      if (this.isFieldEditable(formElement)) {
        if (el.data.required) {
          validators.push(Validators.required);
        }

        if (el.fieldGroup === 'Public' && el.fieldId === "5") {
          validators.push(WebsiteValidator());
        }

        if (el.fieldGroup === 'Public' && el.fieldId === "6") {
          validators.push(Validators.minLength(4), Validators.maxLength(4));
        }
      }

      let value = null;
      try {
        value = this.data.find(
          (fieldData) => fieldData?.fieldGroup === el.fieldGroup && fieldData.fieldId === el.fieldId,
        ).value;
      } catch (error) {}

      this.form.addControl(controlName, new UntypedFormControl(value, validators));
    });

    this.formRows = this.formRows.map((row) => row.filter((item) => !!item)).filter((row) => row?.length > 0);
  }

  public getData(): any {
    const formValue = this.form.value;
    const fields = this.fields.map((field) => {
      return {
        controlType: field.controlType,
        type: field.type,
        fieldId: field.fieldId,
        fieldGroup: field.fieldGroup,
        value: formValue[field.id],
      };
    });
    return {
      fields,
    };
  }

  public isValid(): boolean {
    return this.form.valid;
  }

  public isFieldEditable(field: CustomFormElement): boolean {
    return field?.fieldGroup !== CustomFormFieldGroup.Basic;
  }
}
