import { CustomFieldType } from '@clover/custom-fields/models/custom-field';
import { CustomFormFieldType, CustomFormFieldGroup } from '../models/enums';
import { type CustomFormElement } from '../models/form-element';
import { ControlTypes } from './form-control-types';

export const BasicElements: CustomFormElement[] = [
  {
    fieldId: '0',
    fieldGroup: CustomFormFieldGroup.Basic,
    controlType: CustomFormFieldType.Header,
    icon: ControlTypes.Header.icon,
    label: 'Section Header',
    data: {
      headerText: 'Section Header',
      textAlignment: 'left',
      visible: true,
    },
  },
  {
    label: 'Text',
    fieldId: "1",
    fieldGroup: CustomFormFieldGroup.Basic,
    controlType: CustomFormFieldType.Label,
    icon: ControlTypes.Label.icon,
    data: {
      text: { ops: [{ insert: 'Text' }] },
      visible: true,
    },
  },
];

export const LogicFields: CustomFormElement[] = [
  {
    fieldGroup: CustomFormFieldGroup.Logic,
    controlType: CustomFormFieldType.Text,
    type: CustomFieldType.Text,
    icon: ControlTypes.Text.icon,
    label: 'Input',
    data: {
      title: 'Input',
      visible: true,
      required: true,
    },
  },
  {
    fieldGroup: CustomFormFieldGroup.Logic,
    controlType: CustomFormFieldType.SingleSelect,
    type: CustomFieldType.OptionList,
    icon: ControlTypes.SingleSelectList.icon,
    label: 'Option List',
    data: {
      title: 'Option List',
      visible: true,
      required: true,
    },
  },
  {
    fieldGroup: CustomFormFieldGroup.Logic,
    controlType: CustomFormFieldType.MultiSelect,
    type: CustomFieldType.MultiOptionList,
    icon: ControlTypes.MultiSelectList.icon,
    label: 'Multiselect Option List',
    data: {
      title: 'Multiselect Option List',
      visible: true,
      required: true,
    },
  },
];
