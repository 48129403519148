import { Component, Input } from '@angular/core';
import { CustomFormElement, type CustomFormElementInstance } from '../../models/form-element';
import { type SelectOption } from '@core/components/select/select.component';
import { FormsModule } from '@angular/forms';
import { SelectComponent } from '../../../core/components/select/select.component';
import { TooltipComponent } from '../../../core/components/tooltip/tooltip.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'fb-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TooltipComponent, SelectComponent, FormsModule],
})
export class SingleSelectComponent implements CustomFormElementInstance {
  @Input() element: CustomFormElement;
  @Input() valueChangeFunc: (value: string) => void;
  @Input() disabled: boolean;
  @Input() controlName: string;
  public selectOptions: SelectOption[] = [];

  private _value: string;

  public get value(): string {
    return this._value;
  }

  public set value(val: string) {
    this._value = val;
    this.valueChangeFunc(val);
  }

  public ngOnInit(): void {
    if (this.disabled || !this.element.options) {
      return;
    }
    this.selectOptions = this.element.options.map((opt) => {
      return {
        label: opt.title,
        value: opt.key,
      };
    });
  }

  public getValue(): string {
    return this._value;
  }

  public setValue(value: string): void {
    this._value = value;
  }
}
