import { Component, Input, type SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation, output } from '@angular/core';
import { CustomFormControl, type CustomFormElementProperty } from '../../models/form-element';
import { CustomFormElementPropertyType, CustomFormFieldGroup } from '../../models/enums';
import { TranslateModule } from '@ngx-translate/core';
import { RichTextEditorComponent } from '@core/components/rich-text-editor/rich-text-editor.component';
import { ToggleComponent } from '@design/forms/toggle/toggle.component';
import { SelectComponent } from '@core/components/select/select.component';
import { FormsModule } from '@angular/forms';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'field-edit-form',
  templateUrl: './field-edit-form.component.html',
  styleUrls: ['./field-edit-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgTemplateOutlet, FormsModule, SelectComponent, ToggleComponent, RichTextEditorComponent, TranslateModule],
})
export class FieldEditFormComponent {
  @Input() element: CustomFormControl;
  saveChanges = output<any>();

  @ViewChild('textInput') textInputRef: TemplateRef<any>;
  @ViewChild('select') selectRef: TemplateRef<any>;
  @ViewChild('switcher') switcherRef: TemplateRef<any>;
  @ViewChild('textArea') textAreaRef: TemplateRef<any>;
  @ViewChild('multilineText') multilineTextRef: TemplateRef<any>;

  public data: Record<string, any> = {};
  public properties: CustomFormElementProperty[] = [];
  /*   public ngOnInit(): void {
    this.data = { ...this.element.data };
  } */

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.element) {
      this.data = { ...this.element.data };
      setTimeout(() => {
        this.properties = (
          this.element.fieldGroup === CustomFormFieldGroup.Logic
            ? this.element.logicProperties
            : this.element.properties
        ).filter((prop) => !prop.isNotEditable);
      }, 0);
    }
  }

  public save(): void {
    this.saveChanges.emit({
      ...this.data,
    });
  }

  public getPropTemplate(prop: CustomFormElementProperty): TemplateRef<any> {
    switch (prop.type) {
      case CustomFormElementPropertyType.TextInput:
        return this.textInputRef;

      case CustomFormElementPropertyType.Select:
        return this.selectRef;

      case CustomFormElementPropertyType.Switcher:
        return this.switcherRef;

      case CustomFormElementPropertyType.TextArea:
        return this.textAreaRef;

      case CustomFormElementPropertyType.MultilineText:
        return this.multilineTextRef;

      default:
        return null;
    }
  }
}
