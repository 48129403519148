import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
  ViewChild,
  type ViewContainerRef,
} from '@angular/core';
import { FormHostDirective } from '@core/directives/form-host.directive';
import { CustomFormControl } from '../../models/form-element';
import { TranslateModule } from '@ngx-translate/core';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { DraggableDirective } from '@core/directives/draggable.directive';
import { DroppableDirective } from '@core/directives/droppable.directive';

@Component({
  selector: 'form-element-container',
  templateUrl: './form-element-container.component.html',
  styleUrls: ['./form-element-container.component.scss'],
  standalone: true,
  imports: [
    DroppableDirective,
    DraggableDirective,
    AssetSrcDirective,
    FormHostDirective,
    FocusableDirective,
    TranslateModule,
  ],
})
export class FormElementContainerComponent {
  public readonly elementRef = inject(ElementRef);
  @Input() element: CustomFormControl;
  @Input() error: string;
  @Input() isSelectedFunc: (el: CustomFormControl) => boolean;
  @ViewChild(FormHostDirective, { static: true })
  appFormHost: FormHostDirective;
  @Output() editClick = new EventEmitter<CustomFormControl>();
  @Output() deleteClick = new EventEmitter<CustomFormControl>();
  private readonly componentFactoryResolver = inject(ComponentFactoryResolver);
  private viewContainerRef: ViewContainerRef;

  @HostBinding('class.selected')
  public get selected(): boolean {
    if (!this.isSelectedFunc) {
      return false;
    }

    return this.isSelectedFunc(this.element);
  }

  public ngOnInit(): void {
    if (!this.element) {
      return;
    }

    const factory = this.componentFactoryResolver.resolveComponentFactory(this.element.component);
    this.viewContainerRef = this.appFormHost.viewContainerRef;
    const componentRef = this.viewContainerRef.createComponent(factory);
    (componentRef.instance as any).element = this.element;
    (componentRef.instance as any).disabled = true;
  }

  public onEditClick(event: MouseEvent): void {
    event.stopPropagation();
    this.editClick.emit(this.element);
  }

  public onDeleteClick(event: MouseEvent): void {
    event.stopPropagation();
    this.deleteClick.emit(this.element);
  }
}
