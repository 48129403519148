import { Component, Input, type OnInit } from '@angular/core';
import { CustomFormControl } from '../../models/form-element';
import { TooltipComponent } from '../../../core/components/tooltip/tooltip.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'fb-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TooltipComponent],
})
export class ImageComponent implements OnInit {
  @Input() element: CustomFormControl;

  public ngOnInit(): void {}
}
