import { Injectable, inject } from '@angular/core';
import { HttpService } from '../core/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class CustomFormsService {
  private readonly http = inject(HttpService);

  public async getCustomFieldsSchema(): Promise<any> {
    return await this.http.get(`api/companies/fields/schema`);
  }

  //TODO: add object definition view model
  public async getObjectDefinitions(): Promise<any> {
    return await this.http.get(`api/business-objects?paging.limit=100`);
  }

  public async getObjectDefinition(id:string): Promise<any> {
    return await this.http.get(`api/business-objects/${id}`);
  }
}
