import { Component, Input, inject } from '@angular/core';
import { CustomFormElement, type CustomFormElementInstance } from '../../models/form-element';
import { ModalService } from '@core/services/modal.service';
import { FormsModule } from '@angular/forms';
import { DatePickerComponent } from '@core/components/date-picker/date-picker.component';
import { TooltipComponent } from '@core/components/tooltip/tooltip.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'fb-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TooltipComponent, DatePickerComponent, FormsModule],
})
export class DateComponent implements CustomFormElementInstance {
  private readonly modalService = inject(ModalService);
  @Input() element: CustomFormElement;
  @Input() valueChangeFunc: (value: string) => void;
  @Input() disabled: boolean;
  @Input() controlName: string;
  private _value: string;

  public get value(): string {
    return this._value;
  }

  public set value(val: string) {
    this._value = val;
    this.valueChangeFunc(val);
  }

  public getValue(): string {
    return this._value;
  }

  public setValue(value: string): void {
    this._value = value;
  }
}
