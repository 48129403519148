<div class="field-edit-form_content">
  @for (prop of properties; track prop) {
    <div class="field-edit-form_property">
      <ng-container
        [ngTemplateOutlet]="getPropTemplate(prop)"
        [ngTemplateOutletContext]="{ $implicit: prop }"
      >
      </ng-container>
    </div>
  }
</div>

<div class="field-edit-form_buttons">
  <button
    class="btn btn-primary submit-btn"
    (click)="save()"
  >
    {{ 'common.buttons.save' | translate }}
  </button>
</div>

<ng-template
  #textInput
  let-prop
>
  <label
    [class.required]="prop.required"
    [for]="prop.key"
    >{{ prop.label | translate }}</label
  >
  <input
    [disabled]="prop.disabled"
    [(ngModel)]="data[prop.key]"
    [id]="prop.key"
    placeholder="{{ prop.placeholder | translate }}"
  />
</ng-template>

<ng-template
  #select
  let-prop
>
  <label
    [class.required]="prop.required"
    [for]="prop.key"
    >{{ prop.label | translate }}</label
  >
  <app-select
    [disabled]="prop.disabled"
    [(ngModel)]="data[prop.key]"
    [id]="prop.key"
    placeholder="{{ prop.placeholder | translate }}"
    [options]="prop.options"
  ></app-select>
</ng-template>

<ng-template
  #switcher
  let-prop
>
  <label
    [class.required]="prop.required"
    [for]="prop.key"
    >{{ prop.label | translate }}</label
  >
  <app-toggle
    [disabled]="prop.disabled"
    [(ngModel)]="data[prop.key]"
    [id]="prop.key"
  ></app-toggle>
</ng-template>

<ng-template
  #multilineText
  let-prop
>
  <label
    [class.required]="prop.required"
    [for]="prop.key"
    >{{ prop.label | translate }}</label
  >
  <textarea
    autocomplete="false"
    type="text"
    [(ngModel)]="data[prop.key]"
    [placeholder]="prop.placeholder"
  ></textarea>
</ng-template>

<ng-template
  #textArea
  let-prop
>
  <label
    [class.required]="prop.required"
    [for]="prop.key"
    >{{ prop.label | translate }}</label
  >
  <rich-text-editor
    [disabled]="prop.disabled"
    [(ngModel)]="data[prop.key]"
    textOnly="true"
  >
  </rich-text-editor>
</ng-template>
